import {useCallback, useEffect, useState} from "react";
import {GET_ALL_GAMES_QUERIES, getAllGames} from "../services/firebase";
import {GameOptions} from "../entities/game";

const useDashboardGames = () => {
  const [games, setGames] = useState(null);
  const [pastGames, setPastGames] = useState(null);
  const [enablePastGames, setEnablePastGames] = useState(false);
  const activeGames = games?.filter(game => !!game.lastActivatedAt && game.getOption(GameOptions.IS_ACTIVE));
  const upcomingGames = games?.filter(game => !game.lastActivatedAt);

  useEffect(() => {
    const subscription = getAllGames([GET_ALL_GAMES_QUERIES.NOT_CLOSED]).subscribe(docs => setGames(docs));
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (enablePastGames) {
      const subscription = getAllGames([GET_ALL_GAMES_QUERIES.CLOSED_NOT_ARCHIVED]).subscribe(docs => setPastGames(docs));
      return () => subscription.unsubscribe();
    }
  }, [enablePastGames]);

  return {
    loadingNonClosedGames: games === null,
    activeGames,
    upcomingGames,
    loadPastGames: () => {
      setEnablePastGames(true);
    },
    enablePastGames,
    pastGames,
    loadingPastGames: pastGames === null && enablePastGames,
  }
}

export default useDashboardGames;