import React, {useEffect, useState} from "react";
import {navigate} from "gatsby";
import {ClueMasterGameList, ClueMasterPageLayout, CLUE_MASTER_NAVIGATION_ACTIVE_KEYS} from "../../components/components";
import {getAllGames, GET_ALL_GAMES_QUERIES} from "../../services/firebase";
import {GameOptions} from "../../entities/game";
import appContent from "../../markdown/app-content";
import useDashboardGames from "../../hooks/useDashboardGames";
import {Button} from "react-bootstrap";


const GamePage = () => {
  const {
    loadingNonClosedGames,
    activeGames,
    upcomingGames,
    loadPastGames,
    enablePastGames,
    pastGames,
    loadingPastGames,
} = useDashboardGames()

  const handleClickedGame = (gameID) => {
    const params = new URLSearchParams({gameID});
    navigate(`/clue-master/game?${params.toString()}`);
  };

  const gameLists = [
    {
      title: appContent.cluemaster.games.gameListActiveLabel,
      subTitle: appContent.cluemaster.games.gameListActiveSubLabel,
      games: activeGames,
      isLoading: loadingNonClosedGames,
    },
    {
      title: appContent.cluemaster.games.gameListNeverActiveLabel,
      subTitle: appContent.cluemaster.games.gameListNeverActiveSubLabel,
      games: upcomingGames,
      isLoading: loadingNonClosedGames,
    },
    {
      title: appContent.cluemaster.games.gameListClosedLabel,
      subTitle: appContent.cluemaster.games.gameListClosedSubLabel,
      games: pastGames,
      isLoading: loadingPastGames,
      renderInstead: !enablePastGames && <Button className="mb-3" block variant="dark" onClick={loadPastGames}>{appContent.cluemaster.games.gamesListLoadButtonText(appContent.cluemaster.games.gameListClosedLabel)}</Button>
    },
  ];

  return <ClueMasterPageLayout activeID={CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.games}>
    {gameLists.map(({title, subTitle, games, isLoading, renderInstead}) => <div key={title}>
      <h1>{title}</h1>
      {subTitle && <p className="text-muted">{appContent.cluemaster.games.gameListActiveSubLabel}</p>}
      {renderInstead || <ClueMasterGameList games={games} onClick={handleClickedGame} isLoading={isLoading} />}
    </div>)}

  </ClueMasterPageLayout>;
};

export default GamePage;
